/**
 * Combine multiple signal together
 * @param {AbortSignal[]} signals
 * @returns {AbortSignal}
 */
export function anySignal(signals: AbortSignal[]): AbortSignal {
  const cleanSignals = signals.filter((s) => Boolean(s));

  const centralController = new AbortController();

  function onAbort() {
    centralController.abort();

    // Cleanup
    for (const signal of cleanSignals) {
      signal.removeEventListener("abort", onAbort);
    }
  }

  for (const signal of cleanSignals) {
    if (signal.aborted) {
      onAbort();
      break;
    }
    signal.addEventListener("abort", onAbort);
  }

  return centralController.signal;
}
