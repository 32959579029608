export function safeJsonParse(text: string | null): unknown | null {
  if (text == null) {
    return null
  }

  try {
    return JSON.parse(text)
  } catch (_) {
    return null
  }
}