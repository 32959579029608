// TODO: make the process more cleaner by refactor them to pipeline pattern
import { stringify } from 'qs';
import { anySignal } from '@bubel/common';
import { __DEV__ } from '@bubel/app-env';

type Config = {
  host: string;
  headers?: Headers;
};

type Init = {
  headers?: Headers;
  signal?: AbortSignal;
};

export type SuccessResponse<T = unknown> = {
  status: 'ok';
  response: T;
};

export type ErrorResponse<T = unknown> = {
  status: 'error';
  error: number;
  error_message: string;
  response?: T;
};

export const isErrorResponse = (arg: unknown): arg is ErrorResponse => {
  return (arg as ErrorResponse)?.status === 'error';
};

export const isSuccessResponse = (arg: unknown): arg is SuccessResponse => {
  return (arg as SuccessResponse)?.status === 'ok';
};

const DEFAULT_JSON_HEADERS = new Headers({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
});

const TIMEOUT_TIME = 30 * 1000;

export function makeJsonGetFetch(config: Config) {
  const { host, headers: configHeaders } = config;

  const headers = mergeHeaders(DEFAULT_JSON_HEADERS, configHeaders);

  return async (
    path: string,
    query?: Record<string, unknown>,
    init?: Init
  ): Promise<SuccessResponse | ErrorResponse> => {
    const { headers: initHeaders, signal } = init ?? {};

    // TODO: handle sanitation
    const fullURL = host + path + (query ? stringify(query) : '');

    const mergedHeaders = initHeaders
      ? mergeHeaders(headers, initHeaders)
      : headers;

    const timeoutController = new AbortController();
    setTimeout(() => {
      timeoutController.abort();
    }, TIMEOUT_TIME);

    let r: Awaited<ReturnType<typeof fetch>>;

    try {
      r = await fetch(fullURL, {
        method: 'GET',
        signal: anySignal(
          [timeoutController.signal, signal].filter((s): s is AbortSignal =>
            Boolean(s)
          )
        ),
        headers: mergedHeaders,
      });
    } catch (e) {
      return {
        status: 'error',
        error: -101,
        error_message: __DEV__ ? `[${-101}] ${String(e)}` : 'Internal Error',
      };
    }

    let result;

    try {
      result = await r.json();
    } catch (_) {}

    if (!r.ok) {
      return {
        status: 'error',
        error: r.status,
        error_message: '',
        response: result,
      };
    }

    return {
      status: 'ok',
      response: result,
    };
  };
}

export function makeJsonPostFetch(config: Config) {
  const { host, headers: configHeaders } = config;

  const headers = mergeHeaders(DEFAULT_JSON_HEADERS, configHeaders);

  return async (path: string, body: Record<string, unknown>, init?: Init) => {
    const { headers: initHeaders, signal } = init ?? {};

    // TODO: handle sanitation
    const fullURL = host + path;

    const mergedHeaders = initHeaders
      ? mergeHeaders(headers, initHeaders)
      : headers;

    const timeoutController = new AbortController();
    setTimeout(() => {
      timeoutController.abort();
    }, TIMEOUT_TIME);

    let r: Awaited<ReturnType<typeof fetch>>;

    try {
      r = await fetch(fullURL, {
        method: 'POST',
        signal: anySignal(
          [timeoutController.signal, signal].filter((s): s is AbortSignal =>
            Boolean(s)
          )
        ),
        body: JSON.stringify(body),
        headers: mergedHeaders,
      });
    } catch (e) {
      return {
        status: 'error',
        error: -101,
        error_message: __DEV__ ? `[${-101}] ${String(e)}` : 'Internal Error',
      };
    }

    let result;

    try {
      result = await r.json();
    } catch (_) {}

    if (!r.ok) {
      return {
        status: 'error',
        error: r.status,
        error_message: '',
        response: result,
      };
    }

    return {
      status: 'ok',
      response: result,
    };
  };
}

export function mergeHeaders(header1?: Headers, header2?: Headers): Headers {
  const newHeader = new Headers(header1);

  header2?.forEach((value, key) => {
    newHeader.set(key, value);
  });

  return newHeader;
}
