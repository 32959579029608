import { h } from 'preact';
import { LoadingSpinner } from '../../../../components/loading';
import { PrimaryButtonContent } from '../../../../components/button';
import { LoginStatus, useLoginViewModel } from '../../../../modules/login';
// import IcWarning from '../../../../assets/icons/ic_warning.svg';

export enum LoginSectionMode {
  enter,
  join,
}

type LoginProps = {
  mode?: LoginSectionMode;
};

interface LoginPageBinding {
  onEmailFieldChange(email: string): void;
  onSubmit(): void;
  emailFieldValue: string;
  errorMessage: string;
  isLoginLoading: boolean;
  successMessage: string;
  loginStatus: LoginStatus | null;
}

function useLoginSectionViewModel(
  props: LoginProps & { loginStatus: LoginStatus | null }
) {
  const buttonText = (() => {
    switch (props.mode) {
      case LoginSectionMode.join: {
        switch (props.loginStatus) {
          case LoginStatus.WAITING:
            return 'Waiting to join...';
          case LoginStatus.REQUESTING:
            return 'Checking...';
        }

        return 'Join';
      }
      case LoginSectionMode.enter:
      default:
        return 'Sign in';
    }
  })();

  return {
    buttonText,
  };
}

function LoginSection(props: LoginProps) {
  const {
    emailFieldValue,
    errorMessage,
    onEmailFieldChange,
    onSubmit,
    isLoginLoading,
    successMessage,
    loginStatus,
  }: LoginPageBinding = useLoginViewModel();
  const { buttonText } = useLoginSectionViewModel({ ...props, loginStatus });

  return (
    <div class="prose m-auto">
      <div class="mx-auto mt-10 mb-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <h2 class="text-2xl tracking-tight sm:text-2xl md:text-2xl">
          Enter with your institution email~
        </h2>
        <div class="flex flex-col">
          <div class="mb-4 w-full md:mb-4">
            <label for="username" class="sr-only">
              Email
            </label>
            <input
              class="w-full"
              id="username"
              name="username"
              type="email"
              value={emailFieldValue}
              onInput={(e) => onEmailFieldChange(e.currentTarget.value)}
            />
            {errorMessage ? (
              <div class="my-2 rounded-md bg-red-400 pl-2 pr-4 flex">
                {/* <img src={IcWarning} width={16} height={16} class="mr-2" /> */}
                <div class=" text-white">{errorMessage}</div>
              </div>
            ) : null}
            {successMessage ? (
              <div class="my-2 rounded-md bg-green-500 pl-2 pr-4 flex">
                <div class=" text-white">{successMessage}</div>
              </div>
            ) : null}
          </div>
          <button
            class="w-full"
            type="submit"
            onClick={onSubmit}
            disabled={isLoginLoading}>
            <PrimaryButtonContent
              disabled={isLoginLoading}
              loadingSpinner={isLoginLoading ? <LoadingSpinner /> : null}>
              {buttonText}
            </PrimaryButtonContent>
          </button>
        </div>
      </div>
    </div>
  );
}

export { LoginSection };
