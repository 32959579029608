import { h } from 'preact';

export function HeroSection() {
  return (
    <main class="mx-auto mt-10 mb-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div class="sm:text-center lg:text-left">
        <h1 class="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
          <span class="block xl:inline">Welcome to Sirkelku</span>
        </h1>
        <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
          Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem
          cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat
          aliqua.
        </p>
      </div>
    </main>
  );
}
