import { h } from 'preact';
import { Link } from 'preact-router/match';
import { SessionManager } from '../../modules/auth';

import { PrimaryButtonContent } from '../button';

const Title = () => (
  <div class="flex justify-start lg:w-0 lg:flex-1">
    <Link href="/">
      <div class="font-mono text-2xl">Sirkelku</div>
    </Link>
  </div>
);

const HeaderDesktop = ({ signIn }: { signIn: boolean }) => {
  return (
    <div class="mx-auto max-w-7xl px-4 sm:px-6">
      <div class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
        <Title />
        <div class="justify-end">
          {signIn ? (
            <a href="#signin">
              <PrimaryButtonContent>Sign in</PrimaryButtonContent>
            </a>
          ) : (
            <a href="/app">
              <PrimaryButtonContent>Enter</PrimaryButtonContent>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const LandingHeader = () => {
  const { signIn } = useLandingHeaderViewModel();
  return (
    <header class="sticky top-0 bg-white">
      <HeaderDesktop signIn={signIn} />
    </header>
  );
};

function useLandingHeaderViewModel() {
  const isLoggedIn = SessionManager.getInstance().isLoggedIn();
  return {
    signIn: !isLoggedIn,
  };
}

export { LandingHeader };
