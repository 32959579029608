import { makeJsonPostFetch } from '../../core/network';
import { SIRKELKU_API_DOMAIN, SIRKELKU_API_URL } from '@bubel/app-env';

const requestPost = makeJsonPostFetch({
  host: SIRKELKU_API_URL,
  headers: new Headers({
    'Access-Control-Allow-Origin': SIRKELKU_API_DOMAIN,
  }),
});

export class AuthService {
  private static _instance: AuthService;

  static getInstance() {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService();
    }

    return AuthService._instance;
  }

  public async enter(email: string) {
    return requestPost('/enter', {
      email,
    });
  }

  public async signin(token: string) {
    return requestPost('/sign_in', {
      token,
    });
  }

  public refresh(token: string) {
    return this.signin(token);
  }
}
