import { h } from 'preact';
import { route, Route, Router, RouterOnChangeArgs } from 'preact-router';
import AsyncRoute from 'preact-async-route';
import { useCallback } from 'preact/hooks';

import { Redirect } from './components/redirect';
import { SessionManager } from './modules/auth';
import { __DEV__ } from '@bubel/app-env';

// Code-splitting is automated for `routes` directory
import Home from './pages/home';

function routerMiddleware(middlewares: Array<any>) {
  return async (e: RouterOnChangeArgs) => {
    for (const m of middlewares) {
      await m(e);
    }
  };
}

function protectedPathMiddleware() {
  // TODO: unify Route and this into one string
  // each page can register to one of these in page level instead of here
  // create a registry
  // create a function to register
  const PROTECTED_PATHS: Set<string> = new Set(['/app']);
  const REDIRECT_AFTER_LOGIN = new Set(['/login', '/']);

  return async (e: RouterOnChangeArgs) => {
    if (
      PROTECTED_PATHS.has(e.url) &&
      !SessionManager.getInstance().isLoggedIn()
    ) {
      route('/login', true);
      return;
    }

    if (
      REDIRECT_AFTER_LOGIN.has(e.url) &&
      SessionManager.getInstance().isLoggedIn()
    ) {
      route('/app', true);
      return;
    }
  };
}

const App = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRouter = useCallback(
    routerMiddleware([protectedPathMiddleware()]),
    []
  );

  return (
    <div id="app">
      <Router onChange={handleRouter}>
        <Route path="/" component={Home} />
        <Redirect path="/login" to="/" />
        <AsyncRoute
          path="/enter"
          getComponent={() =>
            import('./pages/enter-callback').then((m) => m.default)
          }
          loading={() => <div>loading...</div>}
        />
        <AsyncRoute
          path="/app/:chatId?"
          getComponent={() =>
            import('./pages/chat-home').then((m) => m.default)
          }
          loading={() => <div>loading...</div>}
        />
        {__DEV__ && (
          <AsyncRoute
            path="/debug-mode"
            getComponent={() =>
              import('./pages/debug-mode').then((m) => m.default)
            }
            loading={() => <div>loading...</div>}
          />
        )}
        <Route default component={Home} />
      </Router>
    </div>
  );
};

export default App;
