import { h } from 'preact';

import { LandingHeader } from '../../components/header';
import { LoginSection, LoginSectionMode } from './components/login-section';
import Match from 'preact-router/match';
import { HeroSection } from './components/hero-section';

function getUrlBasedLoginMode(url: string) {
  return url.includes('#signin')
    ? LoginSectionMode.enter
    : LoginSectionMode.join;
}

function Home() {
  return (
    <div class="h-screen">
      <LandingHeader />
      <HeroSection />
      <div id="signin">
        <Match path="/">
          {() => {
            return <LoginSection mode={getUrlBasedLoginMode(location.href)} />;
          }}
        </Match>
      </div>
    </div>
  );
}

export default Home;
