import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import type { BaseButtonContentProps } from './types';

export type PrimaryButtonContentProps = BaseButtonContentProps;

export function PrimaryButtonContent(props: PrimaryButtonContentProps) {
  const className = useMemo(() => {
    return [
      'w-full',
      'inline-flex',
      'items-center',
      'justify-center',
      'whitespace-nowrap',
      'rounded-md border',
      'border-transparent',
      props.disabled ? ' bg-gray-600' : 'bg-black',
      'px-4',
      'py-2',
      'text-base',
      'font-medium',
      'text-white',
      'shadow-sm',
      'hover:bg-neutral-800',
      props.class,
    ].join(' ');
  }, [props.class, props.disabled]);

  return (
    <div class={className}>
      <div class="relative">
        <div class="absolute bottom-0 w-6" style={{ top: 0, left: -42 }}>
          {props.loadingSpinner ?? null}
        </div>
        {props.children}
      </div>
    </div>
  );
}
