import type { Session, SignInResponseData } from './types';
import { signal, Signal } from '@preact/signals';
import { safeJsonParse, isServer, isBrowser } from '@bubel/common';
import { AuthService } from './auth-service';
import { isErrorResponse } from '../../core/network';

const SESSION_STORAGE_KEY = 'userSession';

export class SessionManager {
  private currentSession: Signal<Session | null>;
  private authService: AuthService;

  static SESSION_STORAGE_KEY = SESSION_STORAGE_KEY;

  private static _instance: SessionManager;

  static getInstance() {
    if (!SessionManager._instance) {
      SessionManager._instance = new SessionManager(AuthService.getInstance());
    }
    return SessionManager._instance;
  }

  constructor(authService: AuthService) {
    this.currentSession = signal(null);
    this.authService = authService;
    this.onInit();
  }

  private async onInit() {
    if (isServer()) {
      return;
    }
    this.loadCurrentSession();

    const token = this.getCurrentUserSession().peek()?.authorizationKey;

    if (token) {
      await this.refreshSession(token);
    }
  }

  private loadCurrentSession() {
    let sessionData = safeJsonParse(
      window.localStorage.getItem(SESSION_STORAGE_KEY)
    ) as Session | null;

    if (sessionData == null) {
      sessionData = {
        user: null,
        authorizationKey: null,
        key: null,
      };
    }

    this.currentSession.value = sessionData;
  }

  private clearCurrentSession() {
    this.setCurrentSession({ authorizationKey: null, user: null });
  }

  private setCurrentSession(
    session: Partial<Pick<Session, 'user' | 'authorizationKey'>>
  ) {
    const newSession: Session = {
      ...this.currentSession.peek(),
      ...session,
    };

    // newSession.key = this.currentSession.peek()?.key ?? generatePrivateKey();

    this.currentSession.value = newSession;
    isBrowser() &&
      window.localStorage.setItem(
        SESSION_STORAGE_KEY,
        JSON.stringify(newSession)
      );
  }

  public getCurrentUserSession(): Signal<Session | null> {
    return this.currentSession;
  }

  /**
   * Check and observe the session login status
   * @returns boolean
   */
  public isLoggedIn(): boolean {
    return this.getCurrentUserSession().value?.authorizationKey != null;
  }

  public peekLoggedIn(): boolean {
    return this.getCurrentUserSession().peek()?.authorizationKey != null;
  }

  public async refreshSession(token: string) {
    const resp = await this.authService.refresh(token);
    if (isErrorResponse(resp)) {
      this.clearCurrentSession();
    } else {
      const response = resp.response as SignInResponseData;

      this.setCurrentSession({
        user: {
          email: response.account.email,
          username: response.account.username,
        },
        authorizationKey: response.auth_token,
      });
    }

    return resp;
  }

  public async setAuthAndRefresh(token: string) {
    this.setCurrentSession({ authorizationKey: token });
    return await this.refreshSession(token);
  }

  public async waitingForCallback(arg: undefined | { signal: AbortSignal }) {
    return new Promise<void>((resolve) => {
      window.addEventListener(
        'storage',
        (msg) => {
          if (msg.key === SessionManager.SESSION_STORAGE_KEY) {
            this.onInit().finally(() => {
              setTimeout(() => {
                resolve();
              }, 10);
            });
          }
        },
        {
          signal: arg?.signal,
          once: true,
        }
      );
    });
  }
}
