import { isValidEmail } from '@bubel/common';
import { route } from 'preact-router';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
} from 'preact/hooks';
import {
  ErrorResponse,
  isErrorResponse,
  SuccessResponse,
} from '../../core/network';
import { AuthService, SessionManager } from '../auth';

export enum LoginStatus {
  WAITING = 'waiting',
  REQUESTING = 'requesting',
  ERROR = 'error',
  ERROR_CALLBACK = 'error_callback',
  OK = 'ok',
}

type LoginState = {
  email: string;
  emailErrorCode: null | number;
  loginStatus: null | LoginStatus;
  loginErrorMessage: null | string;
};

const loginInitialState: Readonly<LoginState> = Object.freeze({
  email: '',
  emailErrorCode: null,

  loginStatus: null,
  loginErrorMessage: null,
});

enum ActionType {
  CHANGE_EMAIL = 1,
  RESET = 2,
  VALIDATE = 3,
  REQUEST_LOGIN = 4,
  PROCESS_LOGIN_RESPONSE = 5,
  SET_LOGIN_CALLBACK_FAILED = 6,
}

type Action =
  | {
      type: ActionType.CHANGE_EMAIL;
      payload: {
        email: string;
      };
    }
  | {
      type:
        | ActionType.RESET
        | ActionType.VALIDATE
        | ActionType.SET_LOGIN_CALLBACK_FAILED
        | ActionType.REQUEST_LOGIN;
    }
  | {
      type: ActionType.PROCESS_LOGIN_RESPONSE;
      payload: unknown;
    };

function emailReducer(state: LoginState, action: Action): LoginState {
  switch (action.type) {
    case ActionType.CHANGE_EMAIL: {
      return { ...state, email: action.payload.email };
    }

    case ActionType.RESET: {
      return loginInitialState;
    }

    case ActionType.VALIDATE: {
      const newState = { ...state };
      if (!isValidEmail(newState.email)) {
        newState.emailErrorCode = -1;
      } else {
        newState.emailErrorCode = null;
      }
      return newState;
    }

    case ActionType.PROCESS_LOGIN_RESPONSE: {
      const data = action.payload as SuccessResponse | ErrorResponse;
      if (isErrorResponse(data)) {
        return {
          ...state,
          loginStatus: LoginStatus.ERROR,
          loginErrorMessage: data.error_message,
        };
      }

      return {
        ...state,
        loginStatus: LoginStatus.WAITING,
      };
    }

    case ActionType.REQUEST_LOGIN: {
      return {
        ...state,
        loginStatus: LoginStatus.REQUESTING,
      };
    }

    case ActionType.SET_LOGIN_CALLBACK_FAILED: {
      return {
        ...state,
        loginStatus: LoginStatus.ERROR_CALLBACK,
      };
    }

    default:
      return state;
  }
}

interface LoginViewModel {
  onEmailFieldChange(email: string): void;
  onSubmit(): void;
  emailFieldValue: string;
  errorMessage: string;
  successMessage: string;
  isLoginLoading: boolean;
  loginStatus: LoginStatus | null;
}

export function useLoginViewModel(): LoginViewModel {
  const [state, dispatch] = useReducer(emailReducer, loginInitialState);

  const onEmailFieldChange = useCallback((email: string) => {
    dispatch({ type: ActionType.CHANGE_EMAIL, payload: { email } });
  }, []);

  const onSubmit = useCallback(async () => {
    if (!state.email) {
      dispatch({ type: ActionType.VALIDATE });
      return;
    }

    if (state.emailErrorCode) {
      alert(String(state.emailErrorCode));
      return;
    }

    // waiting
    dispatch({ type: ActionType.REQUEST_LOGIN });

    const result = await AuthService.getInstance().enter(state.email);
    dispatch({
      type: ActionType.PROCESS_LOGIN_RESPONSE,
      payload: result,
    });
  }, [state.email, state.emailErrorCode]);

  useLayoutEffect(() => {
    if (state.email) {
      dispatch({ type: ActionType.VALIDATE });
    } else {
      dispatch({ type: ActionType.RESET });
    }
  }, [state.email]);

  useEffect(() => {
    const cancel = new AbortController();

    if (state.loginStatus === 'waiting') {
      SessionManager.getInstance()
        .waitingForCallback({
          signal: cancel.signal,
        })
        .then(() => {
          if (SessionManager.getInstance().peekLoggedIn()) {
            route('/app', true);
          } else {
            dispatch({ type: ActionType.SET_LOGIN_CALLBACK_FAILED });
          }
        });
    }

    return () => {
      cancel.abort();
    };
  }, [state.loginStatus]);

  const isLoginLoading = state.loginStatus
    ? [(LoginStatus.REQUESTING, LoginStatus.WAITING)].includes(
        state.loginStatus
      )
    : false;

  return {
    isLoginLoading,
    emailFieldValue: state.email,
    // TODO: translate the error code to error msg
    // TODO: consider login error message
    // TODO: consider callback error msg
    errorMessage: state.loginErrorMessage
      ? state.loginErrorMessage
      : state.emailErrorCode
      ? String(state.emailErrorCode)
      : '',
    successMessage:
      state.loginStatus === LoginStatus.WAITING
        ? 'Please check your email to continue!'
        : '',
    onEmailFieldChange,
    onSubmit,
    loginStatus: state.loginStatus,
  };
}
